import { Link, Redirect, Route, Switch } from "wouter";

import logo from './logo.svg';
import './global.css';

import LoginPage from "../pages/loginPage.js";
import GithubRedirect from "../pages/githubRedirect.js";


function App() {

  //adds funcitonality to route based on login check
  function firstRoute() {
    return(
      <Redirect to="/login" />
    ) 
  }

  return (
    <Switch>
      <Route path="/" component={firstRoute} />
      <Route path="/login" component={LoginPage} />
      <Route path="/github-redirect" component={GithubRedirect} />

      {/* Default route in a switch */}
      <Route>404: No such page!</Route>
    </Switch>
  );
}

export default App;
