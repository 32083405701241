import '../app/global.css'

export default function githubRedirect() {
    console.log("github Redirect")

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const installation_id = urlParams.get('installation_id')
    console.log(installation_id);

    var theUrl = "http://localhost:8000/github-redirect?installation_id=" + installation_id
    
    fetch(theUrl)
        .then((response) => console.log(response))
    // .then((json) => console.log(json));

    return (
        <main className="flex min-h-screen flex-col items-center justify-between p-24">
          <div>
            <title>Redirect</title>
            <link rel="icon" href="/favicon.ico" />
          </div>
          <div className="flex w-full justify-center items-center bg-gradient-to-t from-white via-white dark:from-black dark:via-black static h-auto w-auto bg-none flex-col">
            {/* <SupersetLogo /> */}
            <p>Thanks for signing up!</p>
            <p><a class="text-blue-600" href="https://github.com/apps/extensly/installations/select_target">login with github</a></p>
          </div>
        </main> 
    )

}