import '../app/global.css'

export default function LoginPage() {

  console.log("loginpage")

    return (
        <main className="flex min-h-screen flex-col items-center justify-between p-24">
          <div>
            <title>Login</title>
            <link rel="icon" href="/favicon.ico" />
          </div>
          <div className="flex w-full justify-center items-center bg-gradient-to-t from-white via-white dark:from-black dark:via-black static h-auto w-auto bg-none flex-col">
            {/* <SupersetLogo /> */}
            <p>Sign up to Extensly</p> 
            <p>Please only install on the repositories you'd like to auto-deploy</p>
            <p><a class="text-blue-600" href="https://github.com/apps/extensly/installations/select_target">login with github</a></p>
          </div>
        </main> 
    ) 

}